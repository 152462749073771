import { acceptHMRUpdate, defineStore } from "pinia";
import { numberWithSpaces } from "~/libs/ui";
import { useDocs } from "~/store/docs";
import { useApi } from "~/store/app/api";

import qs from "qs";

export const usePrograms = defineStore("programs", {
    state: () => {
        const config = useRuntimeConfig();

        return {
            apiBase: config.public.apiBase,
            cache: config.public.cache,
            programsList: [],
            customProgramsList: [],
            filterCustomProgramsList: [],
            age: "",
            clinicId: null,
            type: "",
            home: false,
            programType: null, //0 - complex, 1 - custom
            program: "",
            programCost: "",
            programCostDefault: "",
            programDocs: [],
            leadDoc: null,
            leadOnline: false,
            typeOnline: 0,
            order: false,
            status: false,
        };
    },

    getters: {
        createApiUrl: (state) => (url) => {
            return `${state.apiBase}${url}`;
            // return `${state.apiBase}${url}${
            //     state.cache ? "" : "&" + new Date().getTime()
            // }`;
        },
        cartDetails(state) {
            if (!state.filterCustomProgramsList.length) return [];

            return state.filterCustomProgramsList[0].attributes.Combo
                .filter((combo) =>
                    combo.options.data.some((option) => option.attributes.checked)
                )
                .map((item) => ({
                    item: item,
                    id: item.id,
                    title: item.title,
                    __component: item.__component,
                    options: item.options.data.filter(
                        (option) => option.attributes.checked
                    )
                }));
        },
        cartIsEmpty(state) {
            return !state.cartDetails.length;
        },
        totalSum(state) {
            return Object.values(state.cartDetails)
                .reduce((acc, item) => {
                    const multi =
                        item.options
                            .filter((option) => option.attributes.checked)
                            .reduce((acc, service) => acc + service.attributes.price, 0) ||
                        0;
                    return acc + Number(multi);
                }, 0);
        },
        totalCount(state) {
            return Object.values(state.cartDetails).reduce((acc, item) => {
                const multi = item.options.filter(
                    (option) => option.attributes.checked
                ).length;
                return acc + Number(multi);
            }, 0);
        },
        programsByAges(state) {
            const programs = state.programsList.reduce((acc, program, idx) => {
                const age = program?.attributes?.ages?.data[0]?.attributes?.title;
                if (!Array.isArray(acc[age])) {
                    acc[age] = [];
                }
                acc[age].push(program);
                return acc;
            }, {});
            return programs;
        },
        customProgramsByAges(state) {
            const programs = state.customProgramsList.reduce((acc, program, idx) => {
                const age = program?.attributes?.ages?.data[0]?.attributes?.title;
                if (!Array.isArray(acc[age])) {
                    acc[age] = [];
                }
                acc[age].push(program);
                return acc;
            }, {});
            return programs;
        },
        listAges(state) {
            return Object.keys(state.programsByAges).map((item, idx) => ({
                id: idx,
                title: item
            }));
        },
        listCustomProgramAges(state) {
            function compare(a, b) {
                if (a.title < b.title) {
                    return -1;
                }
                if (a.title > b.title) {
                    return 1;
                }
                return 0;
            }

            return Object.keys(state.customProgramsByAges).map((item, idx) => ({
                id: idx,
                title: item
            })).sort(compare);
        },
        checkIncludeChecked: (state) => (list) => {
            return list.options.data.find((option) => option.attributes.checked);
        },
        checkAgeIncludeChecked: (state) => (list) => {
            for (let combo in list) {
                if (
                    list[combo].options.data.find((option) => option.attributes.checked)
                ) {
                    return true;
                }
            }
        },
        hasAvailablePrograms: (state) => (age, clinicId) => {
            // Check custom programs
            const hasCustomPrograms = state.customProgramsList
                .filter(program => program.attributes?.ages?.data[0]?.attributes?.title === age)
                .some(program => {
                    if (!program.attributes?.Combo) return false;

                    return program.attributes.Combo.some(combo => {
                        if (!combo.options?.data) return false;

                        return combo.options.data.some(option => {
                            const clinicPrice = option.attributes?.price?.find(
                                price => price.clinic?.data?.attributes?.clinicId === clinicId
                            );
                            return clinicPrice && clinicPrice.Price > 0;
                        });
                    });
                });

            // Check complex programs
            const hasComplexPrograms = state.programsList
                .filter(program => program.attributes?.ages?.data[0]?.attributes?.title === age)
                .some(program => {
                    const priceList = program.attributes?.price_list || [];
                    return priceList.some(price =>
                        price.clinic?.data?.attributes?.clinicId === clinicId &&
                        price.price > 0
                    );
                });

            return {
                hasCustomPrograms,
                hasComplexPrograms,
                hasAnyPrograms: hasCustomPrograms || hasComplexPrograms
            };
        }
    },

    actions: {
        async fetchPrograms() {
            const apiStore = useApi();

            let populate = {
                docs: {
                    populate: {
                        photo_mini: "*",
                        clinics: "*"
                    }
                },
                docs_home: {
                    populate: {
                        photo_mini: "*",
                        clinics: "*"
                    }
                },
                Combo: {
                    populate: {
                        Options: {
                            populate: "*"
                        }
                    }
                },
                price: {
                    populate: {
                        clinic: "*"
                    }
                },
                price_home: {
                    populate: {
                        clinic: "*"
                    }
                },
                price_list: {
                    fields: ["price"],
                    populate: {
                        clinic: "*",
                        doc_list: {
                            fields: ["price"],
                            populate: {
                                doc: {
                                    populate: {
                                        photo_mini: "*",
                                        clinics: "*"
                                    }
                                }
                            }
                        }
                    }
                },
                price_list_home: {
                    fields: ["price"],
                    populate: {
                        clinic: "*",
                        doc_list: {
                            fields: ["price"],
                            populate: {
                                doc: {
                                    populate: {
                                        photo_mini: "*",
                                        clinics: "*"
                                    }
                                }
                            }
                        }
                    }
                },
                ages: "*"
            };

            const cache = "&cache=get";
            const query = qs.stringify({
                populate,
                pagination: { pageSize: 1000 },
                sort: "id"
            }, { encode: true });
            const url = `/api/programs?${query}${cache}`;
            console.log(url);
            this.programsList = (await apiStore.fetchData(url));
        },
        async fetchCustomPrograms(clinicId) {
            const apiStore = useApi();

            let populate = {
                Combo: {
                    populate: {
                        options: {
                            populate: {
                                price: {
                                    populate: {
                                        clinic: '*',
                                        docs: '*'
                                    }
                                }
                            }
                        }
                    }
                },
                ages: '*',
            };

            const cache = "&cache=get";
            const query = qs.stringify({
                populate,
                pagination: { pageSize: 1000 },
                sort: "id"
            }, { encode: true });

            const url = `/api/cprograms?${query}${cache}`;
            this.customProgramsList = await apiStore.fetchData(url);
        },
        setServiceChecked(programKey, categoryId, serviceId, component) {
            if (component === "pages.one" &&
                !this.customProgramsList[programKey].attributes.Combo[categoryId].options.data[serviceId].attributes.checked) {
                this.customProgramsList[programKey].attributes.Combo[categoryId].options.data.forEach(service => {
                    service.attributes.checked = false;
                });
            }

            this.customProgramsList[programKey].attributes.Combo[
                categoryId
            ].options.data[serviceId].attributes.checked =
                !this.customProgramsList[programKey].attributes.Combo[categoryId]
                    .options.data[serviceId].attributes.checked;
        },
        createBasketInfo() {
            const docsStore = useDocs();
            const doc = docsStore.getDocById(this.leadDoc);
            let doctor = "Любой врач";
            if (this.leadDoc) {
                doctor = `${doc.attributes.lname} ${doc.attributes.fname}`;
            }

            if (this.programType === 1) {
                let program = "";
                for (let category of this.cartDetails) {
                    program += "<b>" + category.title + ":</b>" + "<br/>";
                    for (let option of category.options) {
                        program +=
                            option.attributes.title +
                            ` -${numberWithSpaces(option.attributes.price)} р` +
                            "<br/>";
                    }
                    program += "<br/>";
                    program += "<br/>";
                }
                return {
                    cost: numberWithSpaces(this.totalSum) + " р",
                    age: this.age,
                    program,
                    doctor
                };
            } else if (this.programType === 0) {
                return {
                    cost: numberWithSpaces(this.programCost) + " р",
                    age: this.age,
                    program: this.home ? this.program + " дома" : this.program,
                    doctor
                };
            }
        },
        setAge(age) {
            this.age = age;
        },
        setClinicId(clinicId) {
            this.clinicId = clinicId;
        },
        filterCustomPrograms() {
            // First, filter by age and create a deep copy to avoid mutations
            this.filterCustomProgramsList = JSON.parse(JSON.stringify(
                this.customProgramsList.filter(program =>
                    program.attributes?.ages?.data[0]?.attributes?.title === this.age
                )
            ));

            // Process each program in the filtered list
            this.filterCustomProgramsList.forEach((program) => {
                if (program.attributes?.Combo) {
                    program.attributes.Combo = program.attributes.Combo.map(combo => {
                        // Process options data if it exists
                        if (combo.options?.data) {
                            // Filter and transform options based on clinic
                            const validOptions = combo.options.data.filter(option => {
                                const clinicPrice = option.attributes?.price?.find(
                                    price => price.clinic?.data?.attributes?.clinicId === this.clinicId
                                );
                                return clinicPrice && clinicPrice.Price > 0;
                            }).map(option => {
                                const clinicData = option.attributes.price?.find(
                                    price => price.clinic?.data?.attributes?.clinicId === this.clinicId
                                );

                                return {
                                    id: option.id,
                                    attributes: {
                                        title: option.attributes.title,
                                        checked: false,
                                        price: clinicData?.Price || 0,
                                        docs: clinicData?.docs?.data || [],
                                        ageId: program.attributes.ages.data[0].id
                                    }
                                };
                            });

                            combo.options.data = validOptions;
                        }
                        return combo;
                    }).filter(combo => combo.options?.data?.length > 0); // Remove combos with no valid options
                }
            });

            // Remove programs that have no valid combos
            this.filterCustomProgramsList = this.filterCustomProgramsList.filter(
                program => program.attributes?.Combo?.length > 0
            );
        }
    }
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(usePrograms, import.meta.hot));
}
